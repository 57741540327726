<template>
    <div class="app">
		<header>
			<a @click.prevent="$router.go(-1)" href="" class="back"><i class="fad fa-angle-left"></i></a>

			<div class="search-group">
				<input type="text" placeholder="Cari Masker" @keypress.enter="searchProduct" v-model="searchKey">
				<i class="far fa-search"></i>
			</div>
		</header>
		
		<div class="container">
			<h6 class="title mt-4">Result For <b>{{ key }}</b></h6>

			<main class="mt-4">
				<div class="products">
					<div class="row">
						<div class="col-lg-6" v-for="product in products" :key="product.id">
							<div class="product mb-4" @click="showProduct(product.id)">
								<div class="image">
									<img :src="product.image_url" alt="Product 1">
								</div>

								<div class="detail">
									<p class="title">{{ product.name }}</p>

									<div class="bottom">
										<p class="price">Rp. {{ Number(product.min).toLocaleString() }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
			key: this.$route.query.key,
			searchKey: '',
			products: ''
		}
	},
	methods: {
		getProduct() {
			axios.get(`search?key=${this.key}`)
				.then(res => {
					this.products = res.data;
				})
				.catch(err => {
					console.log(err.response.data);
				}); 
		},
		showProduct(id) {
			this.$router.push(`/product/${id}`);
		},
		searchProduct() {
			this.key = this.searchKey;
			this.getProduct();
		}
	},
	created() {
		this.getProduct();
	}
}
</script>

<style scoped>

header {
	padding: 5px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--primary);
	border-bottom: 1px solid rgb(236, 236, 236);
}

header a {
	position: relative;
	color: black;
}

.search-group {
	position: relative;
}

.search-group input {
	width: 300px;
	height: 25px;
	background-color: white;
	border-radius: 20px;
	border: none;
	padding: 15px;
	outline: none;
	font-size: .8rem;
}

.search-group i {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: gray;
}

.categories {
	padding: 20px 0;
	display: flex;
	justify-content: space-between;
}

.category {
	cursor: pointer;
}

.category .icon {
	padding: 10px;
	border-radius: 10px;
	width: 70px;
	height: 70px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.09);
}

.category .caption {
	text-align: center;
	font-size: 13px;
	margin-top: 4px;
}

.product {
	overflow: hidden;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 220px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.09);
	cursor: pointer;
}

.product .image {
	height: 150px;
}

.product .detail {
	height: 20px;
	padding: 10px;
}

.detail .title {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin: 0;
	font-size: 14px;
	text-align: left;
}

.detail .bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.bottom .sold {
	margin: 0;
	font-size: 11px;
	color: gray;
}

.bottom .price {
	margin: 0;
	font-size: 14px;
	font-weight: 600;
}

</style>